import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { EventOverviewPageQuery as EventOverviewPageQueryType } from "./__generated__/EventOverviewPageQuery.graphql";
import { EnvironmentOptions } from "../../common/relayEnvironment";
import { preload, usePreloaded } from "../../utils/relay";

export type { EventOverviewPageQuery as EventOverviewPageQueryType } from "./__generated__/EventOverviewPageQuery.graphql";

export const EventOverviewPageQuery = graphql`
  query EventOverviewPageQuery($slug: String!, $userId: ID) {
    eventBySlug(slug: $slug) {
      id
      description
      viewerCanUpdate: viewerCan(action: UPDATE_EVENT)
      ...EventCompetitionsCarouselFragment
      ...EventTeamsFragment @arguments(userIsOrgMember: $userId)
      ...MemberSelectEventFragment
    }
  }
`;

export const eventOverviewPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    const userId = (environment.options as EnvironmentOptions).userId;
    return preload<EventOverviewPageQueryType>(
      environment,
      EventOverviewPageQuery,
      {
        slug,
        userId,
      },
    );
  };
};

export const useEventOverviewPageQuery = () =>
  usePreloaded<EventOverviewPageQueryType>();

import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { CompetitionMembersPageQuery as CompetitionMembersPageQueryType } from "./__generated__/CompetitionMembersPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionMembersPageQuery as CompetitionMembersPageQueryType } from "./__generated__/CompetitionMembersPageQuery.graphql";

export const CompetitionMembersPageQuery = graphql`
  query CompetitionMembersPageQuery($slug: String!) {
    ...UserAutocompleteFragment
    competitionBySlug(slug: $slug) {
      title
      id
      viewerCanEditMembers: viewerCan(action: ADD_COMPETITION_MEMBER)
      ...CompetitionMembersFragment
    }
  }
`;

export const competitionMembersPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionMembersPageQueryType>(
      environment,
      CompetitionMembersPageQuery,
      { slug },
    );
  };
};

export const useCompetitionMembersPageQuery = () =>
  usePreloaded<CompetitionMembersPageQueryType>();

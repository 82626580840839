import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { EntitySubmissionCodeTreePageQuery as EntitySubmissionCodeTreePageQueryType } from "./__generated__/EntitySubmissionCodeTreePageQuery.graphql";
import { parseParams } from "./EntitySubmissionCodePage";
import { preload, usePreloaded } from "../../utils/relay";

export type { EntitySubmissionCodeTreePageQuery as EntitySubmissionCodeTreePageQueryType } from "./__generated__/EntitySubmissionCodeTreePageQuery.graphql";

export const EntitySubmissionCodeTreePageQuery = graphql`
  query EntitySubmissionCodeTreePageQuery(
    $entity: UsernameOrID
    $slug: String!
    $version: Semver
    $fileKind: ProjectVersionFileKind!
    $path: String
  ) {
    competitionBySlug(slug: $slug) {
      submission(entity: $entity) {
        entity {
          username
        }
        version(version: $version) {
          fileByKind(kind: $fileKind) {
            ...FileBrowserFragment @arguments(path: $path)
          }
        }
      }
    }
  }
`;

export const entitySubmissionCodeTreePageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { "*": path, ...params } }: LoaderArgs) => {
    return preload<EntitySubmissionCodeTreePageQueryType>(
      environment,
      EntitySubmissionCodeTreePageQuery,
      { ...parseParams(params), path },
    );
  };
};

export const useEntitySubmissionCodeTreePageQuery = () =>
  usePreloaded<EntitySubmissionCodeTreePageQueryType>();

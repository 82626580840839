import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { EntitySubmissionCodeReadmePageQuery as EntitySubmissionCodeReadmePageQueryType } from "./__generated__/EntitySubmissionCodeReadmePageQuery.graphql";
import { parseParams } from "./EntitySubmissionCodePage";
import { preload, usePreloaded } from "../../utils/relay";

export type { EntitySubmissionCodeReadmePageQuery as EntitySubmissionCodeReadmePageQueryType } from "./__generated__/EntitySubmissionCodeReadmePageQuery.graphql";

export const EntitySubmissionCodeReadmePageQuery = graphql`
  query EntitySubmissionCodeReadmePageQuery(
    $entity: UsernameOrID
    $slug: String!
    $version: Semver
    $fileKind: ProjectVersionFileKind!
  ) {
    competitionBySlug(slug: $slug) {
      submission(entity: $entity) {
        entity {
          username
        }
        version(version: $version) {
          fileByKind(kind: $fileKind) {
            ...FileBrowserFragment
            browse {
              readMeta(path: "README.md") {
                kind
                ... on FileBrowserFileEntry {
                  ...FileViewerFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const entitySubmissionCodeReadmePageQueryLoader = (
  environment: Environment,
) => {
  return ({ params }: LoaderArgs) => {
    return preload<EntitySubmissionCodeReadmePageQueryType>(
      environment,
      EntitySubmissionCodeReadmePageQuery,
      parseParams(params),
    );
  };
};

export const useEntitySubmissionCodeReadmePageQuery = () =>
  usePreloaded<EntitySubmissionCodeReadmePageQueryType>();

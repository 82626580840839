import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { CompetitionRulesPageQuery as CompetitionRulesPageQueryType } from "./__generated__/CompetitionRulesPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionRulesPageQuery as CompetitionRulesPageQueryType } from "./__generated__/CompetitionRulesPageQuery.graphql";

export const CompetitionRulesPageQuery = graphql`
  query CompetitionRulesPageQuery($slug: String!) {
    viewer @ifAllowed {
      id
    }
    competitionBySlug(slug: $slug) {
      id
      title
      latestRule {
        id
        text
      }
      viewerCanUpdate: viewerCan(action: UPDATE_COMPETITION)
      viewerCanAccept: viewerCan(action: CREATE_COMPETITION_RULE_AGREEMENT)
      ...CompetitionAcceptRulesButtonCompetitionFragment
      ...MemberSelectCompetitionFragment
    }
  }
`;

export const competitionRulesPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionRulesPageQueryType>(
      environment,
      CompetitionRulesPageQuery,
      { slug },
    );
  };
};

export const useCompetitionRulesPageQuery = () =>
  usePreloaded<CompetitionRulesPageQueryType>();

import * as Radix from "@radix-ui/react-popover";
import { cn } from "../utils/tailwind";

export const Root = Radix.Root;

export const Trigger = Radix.Trigger;

export const Anchor = Radix.Anchor;

export const Arrow = (props: Radix.PopoverArrowProps) => (
  <Radix.Arrow {...props} className="opacity-10" />
);

export const Portal = Radix.Portal;

export const Content = ({
  className,
  sideOffset,
  ...props
}: Radix.PopoverContentProps) => (
  <Radix.Content
    {...props}
    className={cn("rounded shadow bg-white px-4 py-2", className)}
    sideOffset={sideOffset ?? 5}
  />
);

import { Environment, graphql } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { UserCommentsPageQuery as UserCommentsPageQueryType } from "./__generated__/UserCommentsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { UserCommentsPageQueryType };

export const UserCommentsPageQuery = graphql`
  query UserCommentsPageQuery($username: String!) {
    entityByUsername(username: $username) {
      ...UserCommentsPageFragment
    }
  }
`;

export function userCommentsPageQueryLoader(environment: Environment) {
  return ({ params: { username } }: LoaderArgs) => {
    if (!username) {
      throw new Response("No username", { status: 500 });
    }
    return preload<UserCommentsPageQueryType>(
      environment,
      UserCommentsPageQuery,
      {
        username,
      },
    );
  };
}

export const useUserCommentsPageQuery = () =>
  usePreloaded<UserCommentsPageQueryType>();

import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { ForumEditPageQuery as ForumEditPageQueryType } from "./__generated__/ForumEditPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { ForumEditPageQuery as ForumEditPageQueryType } from "./__generated__/ForumEditPageQuery.graphql";

export const ForumEditPageQuery = graphql`
  query ForumEditPageQuery($slug: String!) {
    forumBySlug(slug: $slug) {
      id
      slug
      title
      shortDescription
      guidelines
      icon
      orderingPriority
    }
  }
`;

export const forumEditPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<ForumEditPageQueryType>(environment, ForumEditPageQuery, {
      slug,
    });
  };
};

export const useForumEditPageQuery = () =>
  usePreloaded<ForumEditPageQueryType>();

import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { CommentPageQuery as CommentPageQueryType } from "./__generated__/CommentPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";
import {
  getVotableOrderParam,
  DEFAULT_VOTABLE_ORDER,
} from "../../utils/votableOrder";

export type { CommentPageQuery as CommentPageQueryType } from "./__generated__/CommentPageQuery.graphql";

export const CommentPageQuery = graphql`
  query CommentPageQuery($id: ID!, $order: VotableOrder!) {
    node(id: $id) {
      ... on Comment {
        id
        topic {
          title
          description
          author {
            username
            ...EntityProfilePicFragment @arguments(thumbnail: true)
          }
          ...TopicLinkFragment
          ...TopicHelmetFragment
          ...VoteDisplayFragment
          ...SubjectSubscriptionButtonFragment
        }
        ...FocusedCommentFragment @arguments(order: $order)
      }
    }
  }
`;

export const commentPageQueryLoader = (environment: Environment) => {
  return ({ params: { id }, request }: LoaderArgs) => {
    const order = getVotableOrderParam(request) || DEFAULT_VOTABLE_ORDER;
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<CommentPageQueryType>(environment, CommentPageQuery, {
      id,
      order,
    });
  };
};

export const useCommentPageQuery = () => usePreloaded<CommentPageQueryType>();

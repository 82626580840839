import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { EntitySubmissionCodeBlobPageQuery as EntitySubmissionCodeBlobPageQueryType } from "./__generated__/EntitySubmissionCodeBlobPageQuery.graphql";
import { parseParams } from "./EntitySubmissionCodePage";
import { preload, usePreloaded } from "../../utils/relay";

export type { EntitySubmissionCodeBlobPageQuery as EntitySubmissionCodeBlobPageQueryType } from "./__generated__/EntitySubmissionCodeBlobPageQuery.graphql";

export const EntitySubmissionCodeBlobPageQuery = graphql`
  query EntitySubmissionCodeBlobPageQuery(
    $entity: UsernameOrID
    $slug: String!
    $version: Semver
    $fileKind: ProjectVersionFileKind!
    $parentPath: String
    $filePath: String
  ) {
    competitionBySlug(slug: $slug) {
      title
      submission(entity: $entity) {
        entity {
          username
        }
        version(version: $version) {
          fileByKind(kind: $fileKind) {
            ...FileBrowserFragment @arguments(path: $parentPath)
            browse {
              readMeta(path: $filePath) {
                kind
                ... on FileBrowserFileEntry {
                  ...FileViewerFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const entitySubmissionCodeBlobPageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { "*": path, ...params } }: LoaderArgs) => {
    if (!path) {
      throw new Response("No path", { status: 500 });
    }
    const parts = path.split("/");
    parts.pop();
    const parentPath = parts.join("/");
    return preload<EntitySubmissionCodeBlobPageQueryType>(
      environment,
      EntitySubmissionCodeBlobPageQuery,
      {
        ...parseParams(params),
        filePath: path,
        parentPath,
      },
    );
  };
};

export const useEntitySubmissionCodeBlobPageQuery = () =>
  usePreloaded<EntitySubmissionCodeBlobPageQueryType>();

import {
  UseMutationConfig,
  PreloadedQuery,
  loadQuery,
  usePreloadedQuery,
  Environment,
} from "react-relay";
import {
  MutationParameters,
  Disposable,
  OperationType,
  GraphQLTaggedNode,
} from "relay-runtime";
import { useLoaderData } from "react-router-dom";

export function commitMutationPromise<TMutation extends MutationParameters>(
  func: (config: UseMutationConfig<TMutation>) => Disposable,
): (config: UseMutationConfig<TMutation>) => Promise<TMutation["response"]> {
  return (config) => {
    return new Promise((resolve, reject) => {
      const { onCompleted, onError, ...rest } = config;
      func({
        ...rest,
        onCompleted: (response, errors) => {
          if (onCompleted) {
            onCompleted(response, errors);
          }
          resolve(response);
        },
        onError: (error) => {
          if (onError) {
            onError(error);
          }
          reject(error);
        },
      });
    });
  };
}

export interface PreloadedData<TQuery extends OperationType> {
  graphql: GraphQLTaggedNode;
  variables: TQuery["variables"];
  query: PreloadedQuery<TQuery>;
}

export const preload = <TQuery extends OperationType>(
  environment: Environment,
  graphql: GraphQLTaggedNode,
  variables: TQuery["variables"] = {},
): PreloadedData<TQuery> => {
  return {
    graphql,
    variables,
    query: loadQuery<TQuery>(environment, graphql, variables),
  };
};

export const reload = <TQuery extends OperationType>(
  environment: Environment,
  preloaded: PreloadedData<TQuery>,
): PreloadedData<TQuery> => {
  const { graphql, variables, query: _query, ...rest } = preloaded;
  return {
    graphql,
    variables,
    query: loadQuery<TQuery>(environment, graphql, variables),
    ...rest,
  };
};

export const usePreloaded = <TQuery extends OperationType>() => {
  const { variables, query, graphql, ...rest } =
    useLoaderData() as PreloadedData<TQuery>;
  return {
    variables,
    query: usePreloadedQuery<TQuery>(graphql, query),
    ...rest,
  };
};

import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { CompetitionCodeTreePageQuery as CompetitionCodeTreePageQueryType } from "./__generated__/CompetitionCodeTreePageQuery.graphql";
import { fileKind } from "./CompetitionCodePage";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionCodeTreePageQuery as CompetitionCodeTreePageQueryType } from "./__generated__/CompetitionCodeTreePageQuery.graphql";

export const CompetitionCodeTreePageQuery = graphql`
  query CompetitionCodeTreePageQuery(
    $slug: String!
    $fileKind: ProjectVersionFileKind!
    $path: String
  ) {
    competitionBySlug(slug: $slug) {
      id
      useCase {
        latest {
          fileByKind(kind: $fileKind) {
            ...FileBrowserFragment @arguments(path: $path)
          }
        }
      }
    }
  }
`;

export const competitionCodeTreePageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { slug, kind, "*": path } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionCodeTreePageQueryType>(
      environment,
      CompetitionCodeTreePageQuery,
      {
        slug,
        fileKind: fileKind(kind),
        path,
      },
    );
  };
};

export const useCompetitionCodeTreePageQuery = () =>
  usePreloaded<CompetitionCodeTreePageQueryType>();

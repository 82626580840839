import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { TopicEditPageQuery as TopicEditPageQueryType } from "./__generated__/TopicEditPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { TopicEditPageQuery as TopicEditPageQueryType } from "./__generated__/TopicEditPageQuery.graphql";

export const TopicEditPageQuery = graphql`
  query TopicEditPageQuery($id: ID!) {
    node(id: $id) {
      ... on Topic {
        id
        url
        title
        description
        ...TopicDeleteButtonFragment
      }
    }
  }
`;

export const topicEditPageQueryLoader = (environment: Environment) => {
  return ({ params: { topicId: id } }: LoaderArgs) => {
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<TopicEditPageQueryType>(environment, TopicEditPageQuery, {
      id,
    });
  };
};

export const useTopicEditPageQuery = () =>
  usePreloaded<TopicEditPageQueryType>();

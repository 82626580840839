import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { ForumScaffoldQuery as ForumScaffoldQueryType } from "./__generated__/ForumScaffoldQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { ForumScaffoldQuery as ForumScaffoldQueryType } from "./__generated__/ForumScaffoldQuery.graphql";

export const ForumScaffoldQuery = graphql`
  query ForumScaffoldQuery($slug: String!) {
    forumBySlug(slug: $slug) {
      id
      ...ForumFragment
    }
  }
`;

export const forumScaffoldQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<ForumScaffoldQueryType>(environment, ForumScaffoldQuery, {
      slug,
    });
  };
};

export const useForumScaffoldQuery = () =>
  usePreloaded<ForumScaffoldQueryType>();

import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { UserOrganizationsPageQuery as UserOrganizationsPageQueryType } from "./__generated__/UserOrganizationsPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { UserOrganizationsPageQuery as UserOrganizationsPageQueryType } from "./__generated__/UserOrganizationsPageQuery.graphql";

export const UserOrganizationsPageQuery = graphql`
  query UserOrganizationsPageQuery($username: String!) {
    viewer @ifAllowed {
      canCreateOrganization: can(action: CREATE_ORGANIZATION)
    }
    entityByUsername(username: $username) {
      ... on User {
        ...UserOrganizationsFragment
      }
    }
  }
`;

export const userOrganizationsPageQueryLoader = (environment: Environment) => {
  return ({ params: { username } }: LoaderArgs) => {
    if (!username) {
      throw new Response("No username", { status: 500 });
    }
    return preload<UserOrganizationsPageQueryType>(
      environment,
      UserOrganizationsPageQuery,
      { username },
    );
  };
};

export const useUserOrganizationsPageQuery = () =>
  usePreloaded<UserOrganizationsPageQueryType>();

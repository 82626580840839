import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { TopicPageQuery as TopicPageQueryType } from "./__generated__/TopicPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";
import {
  getVotableOrderParam,
  DEFAULT_VOTABLE_ORDER,
} from "../../utils/votableOrder";

export type { TopicPageQuery as TopicPageQueryType } from "./__generated__/TopicPageQuery.graphql";

export const TopicPageQuery = graphql`
  query TopicPageQuery($id: ID!, $order: VotableOrder!) {
    viewer @ifAllowed {
      ...TopicCommentsFormViewerFragment
    }
    node(id: $id) {
      ... on Topic {
        id
        title
        url
        createdAt
        competition {
          slug
        }
        forum {
          slug
        }
        author {
          username
          ...EntityProfilePicFragment @arguments(thumbnail: true)
        }
        description
        viewerCanEdit: viewerCan(action: UPDATE_TOPIC)
        ...TopicCommentsFormTopicFragment
        ...TopicCommentsFragment @arguments(order: $order)
        ...VoteDisplayFragment
        ...SubjectSubscriptionButtonFragment
        ...TopicHelmetFragment
      }
    }
  }
`;

export const topicPageQueryLoader = (environment: Environment) => {
  return ({ params: { topicId: id }, request }: LoaderArgs) => {
    const order = getVotableOrderParam(request) || DEFAULT_VOTABLE_ORDER;
    if (!id) {
      throw new Response("No id", { status: 500 });
    }
    return preload<TopicPageQueryType>(environment, TopicPageQuery, {
      id,
      order,
    });
  };
};

export const useTopicPageQuery = () => usePreloaded<TopicPageQueryType>();

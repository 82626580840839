import React from "react";
import * as Popover from "./Popover";
import clsx from "clsx";

export interface DropdownProps {
  trigger: React.ReactElement;
  children: React.ReactNode;
}

export function Dropdown({ trigger, children }: DropdownProps) {
  if (!children) {
    return React.cloneElement(trigger, { disabled: true });
  }
  return (
    <Popover.Root>
      <Popover.Trigger asChild>{trigger}</Popover.Trigger>
      <Popover.Portal>
        <Popover.Content asChild>
          <div
            className="flex flex-col min-w-60"
            style={{ padding: "0.25rem" }}
          >
            <Popover.Arrow />
            {children}
          </div>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
}

Dropdown.Item = DropdownItem;
Dropdown.List = DropdownList;
export default Dropdown;

export interface DropdownListProps {
  header?: React.ReactNode | undefined;
  children: React.ReactNode;
}

export function DropdownList({ header, children }: DropdownListProps) {
  return (
    <>
      {React.isValidElement(header) && (
        <h4 className="text-sm font-semibold px-6 py-2 pointer-events-none select-none">
          {header}
        </h4>
      )}
      <div className="flex flex-col space-y-1">{children}</div>
    </>
  );
}

export interface DropdownItemProps
  extends React.DetailedHTMLProps<
    React.HTMLProps<HTMLSpanElement>,
    HTMLSpanElement
  > {
  icon?: React.ReactNode | undefined;
  children: React.ReactNode;
}

export function DropdownItem({
  icon,
  children,
  disabled,
  className,
  ...props
}: DropdownItemProps) {
  return (
    <span
      {...props}
      className={clsx(
        className,
        disabled
          ? "text-gray-500"
          : "hover:bg-gray-100 hover:text-slate-500 cursor-pointer",
        "rounded-lg px-4 py-2 flex items-center",
      )}
    >
      <span className="flex-grow pointer-events-none select-none">
        {children}
      </span>
      {React.isValidElement(icon) && <span className="text-xl">{icon}</span>}
    </span>
  );
}

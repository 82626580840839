import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../common/routes";
import { CompetitionCodeBlobPageQuery as CompetitionCodeBlobPageQueryType } from "./__generated__/CompetitionCodeBlobPageQuery.graphql";
import { fileKind } from "./CompetitionCodePage";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionCodeBlobPageQuery as CompetitionCodeBlobPageQueryType } from "./__generated__/CompetitionCodeBlobPageQuery.graphql";

export const CompetitionCodeBlobPageQuery = graphql`
  query CompetitionCodeBlobPageQuery(
    $slug: String!
    $fileKind: ProjectVersionFileKind!
    $parentPath: String
    $filePath: String
  ) {
    competitionBySlug(slug: $slug) {
      id
      title
      useCase {
        latest {
          fileByKind(kind: $fileKind) {
            ...FileBrowserFragment @arguments(path: $parentPath)
            browse {
              readMeta(path: $filePath) {
                kind
                ... on FileBrowserFileEntry {
                  ...FileViewerFragment
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const competitionCodeBlobPageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { slug, kind, "*": path } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    if (!path) {
      throw new Response("No path", { status: 500 });
    }
    const parts = path.split("/");
    parts.pop();
    const parentPath = parts.join("/");
    return preload<CompetitionCodeBlobPageQueryType>(
      environment,
      CompetitionCodeBlobPageQuery,
      {
        slug,
        fileKind: fileKind(kind),
        filePath: path,
        parentPath,
      },
    );
  };
};

export const useCompetitionCodeBlobPageQuery = () =>
  usePreloaded<CompetitionCodeBlobPageQueryType>();
